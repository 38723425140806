.navi_block{
    width: 300px;
}
.navi{
    height: 400px;
    width: 250px;
    background-color: rgba(18, 24, 3, 0.545);
    margin-top: 160px;
    margin-left: 30px;
    border-radius: 10px;
    border: 3px solid white;
    position: fixed;
    color: white;
    text-align: center;
    font-size: 24px;
    line-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.navi div{
    cursor: pointer;
}