/* body{
  background-image: url(images/background.png);
} */
*{
  padding: 0;
  margin: 0;
}

a{
  background-color: transparent;
}

html,body{
  margin: 0;
  padding: 0;
  height: 100%;
}

.App{
  background-image: url(images/camuflage3.jpg);
  background-size: auto;
  background-repeat:repeat;
  position: relative;
  /* height: 100%; */
}
.App h1{
  text-align: center;
  /* margin-top: 25px; */
  font-size: 65px;
  color: rgb(61, 199, 61);
  font-family:'Courier New', Courier, monospace;
}
.apply{
  position: fixed;
  z-index: 20;
  height: 50px;
  width: 400px;
  background-color: white;
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  display: none;
}
.apply.visible {
  display: block;
}
.apply_text{
  text-align: center;
  margin-top: 5px;
  color: black;
  font-weight: 700;
  font-size: 25px;
}

@media(max-width:420px){
  .apply{
    width: 300px;
  }
}