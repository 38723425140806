.header_block{
    position: fixed;
    top: 40px;
    left: 49.9%;
    transform: translate(-50%, -50%);
    z-index: 5;
    /* background-color: rgb(188, 227, 80); */
    background-image: url(../../images/camuflage3.jpg);
    width: 100%;
}
.header_title-block{
    /* background-color: rgb(51, 68, 6); */
    max-width: 600px;
    margin: 0 auto;
    /* padding-top: -100px; */
    /* border:1px solid rgb(214, 242, 101); ;
    border-radius: 10px; */
    margin-top: 80px;

}
.header_title-block h1{
    text-align: center;
    /* padding-top: 50px; */
    font-size: 65px;
    color: white;
    font-family:'Courier New', Courier, monospace;
}

.header_line-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.line{
    /* width: 700px; */
    width: 45vw;
    height: 3px;
    background-color: white;
    margin-top: 20px;
}
.line_img{
    margin-left: 10px;
    margin-right: 10px;
    height: 50px;
    width: 40px;
    color: white;
}

.cart_icon{
    height: 50px;
    width: 50px;
    /* color: rgb(171, 199, 61); */
    color: rgb(249, 249, 248);
    position: absolute;
    right: 50px;
    top: 95px;
    cursor: pointer;
}
.header_cart-amount{
    position: absolute;
    right: 40px;
    top: 80px;
    font-weight: 600;
}

@media(max-width:800px){
    /* .cart_icon{
        top: 150px;
    } */
    /* .header_cart-amount{
        top: 135px;
    } */
    .header_block h1{
        font-size: 55px;
    }
}
@media(max-width:700px){
    .line{
        width: 40vw;
    }
}
@media(max-width:600px){
    .cart_icon{
        top: 210px;
    }
    .header_cart-amount{
        top: 200px;
    }
}
@media(max-width:570px){
    .cart_icon{
        right: 30px;
    }
    .header_cart-amount{
        right: 20px;
    }
}
@media(max-width:500px){
    .header_block h1{
        font-size: 45px;
    }
    .cart_icon{
        height: 40px;
        width: 40px;
        top: 120px;
    }
    .header_cart-amount{
        top: 115px;
    }
}
@media(max-width:470px){
    .cart_icon{
        right: 20px;
    }
    .header_cart-amount{
        right: 10px;
    }
}
@media(max-width:430px){
    .header_block h1{
        font-size: 40px;
    }
    .header_block{
        top: 45px;
    }
}